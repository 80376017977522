.size_selector_wrapper {
  width: 100%;
  display: grid;
  padding: 0.5rem;
  padding-top: 0.5rem;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  & .size_selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #aaa;
    width: 10rem;
    height: 8rem;
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    & .size_name {
      font-size: 1.2rem;
      font-weight: 500;
    }
    & .size_inventory {
      font-size: 0.9rem;
      color: #555;
      padding: 10px;
      text-align: center;
      span {
        font-weight: bold;
        display: block;
      }
    }
    & .size_price {
      font-size: 0.9rem;
      color: #666;
    }
    & .quantity_controls {
      position: absolute;
      bottom: -1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;

      & .quantity_buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eee; /* Green */
        border: 1px solid black;
        text-align: center;
        text-decoration: none;
        font-size: 1rem;
        width: 0.8rem;
        height: 0.8rem;
        padding: 0.8rem;
        color: white;
        border: none;
        background-color: rgba(79, 70, 229, 0.7);
      }
      & .quantity {
        box-sizing: border-box;
        width: 3rem;
        height: 0.7rem;
        padding: 0.7rem;
        border: 1px solid #ccc;
        text-align: center;

        -moz-appearance: textfield;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      & .add_button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eee; /* Green */
        border: 1px solid black;
        text-align: center;
        text-decoration: none;
        font-size: 0.6rem;
        width: 100%;
        height: 0.8rem;
        padding: 0.8rem;
        color: white;
        border: none;
        background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
      }
    }
    & .backorder {
      bottom: -3.4rem;
      & .quantity_buttons {
        background-color: rgba(234, 88, 12, var(--tw-bg-opacity));
      }
    }
  }
  & .active {
    border: 2px solid rgba(79, 70, 229, 0.7);
    margin-bottom: 3rem;
  }
  & .discontinued {
    background-color: #eee;
  }
}
